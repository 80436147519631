<!--<app-error-message *ngIf="questionSubmissionError">-->
<!--  <p>There was an error submitting your answer, we have notified our support team.  Please try again or contact support for additional help.</p>-->
<!--</app-error-message>-->
<app-site-header [canvasAccount]="questionDisplay?.canvasAccountId"></app-site-header>
<div class="qt-layout-constraint {{ questionDisplay?.canvasAccountId != null ? 'canvas-account-' + questionDisplay.canvasAccountId : null }}">
  <app-error-message *ngIf="error === true && learnerMatch"></app-error-message>
  <app-error-message *ngIf="error === true && !learnerMatch">
    <p>There was an error accessing the question.  Our support team has been notified, please try again or contact support for additional help.</p>
  </app-error-message>
  <!-- Until we figure out how to have a global service display this consistently we have to duplicate it here
    It likely will require merging platform-question-container with platform-question-display
  -->
  <div class="global-loader-spinner-wrap" *ngIf="loadingMessage$.value !== null">
    <div class="question card">
      <div class="card-header">
        <div class="global-loader-spinner">
          <div class="loader"><div class="ball-pulse"><div></div><div></div><div></div></div></div> <span class="global-loader-message">{{ loadingMessage$ | async }}</span>
        </div>
      </div>
    </div>
  </div>
  <app-platform-question-display
    *ngIf="questionDisplay !== null"
    [questionDisplay]="questionDisplay"
    [isDemo]="isDemo"
    [slug]="slug"
    [newView]="sendView"
    [attemptNo]="attemptNo"
    [answerResultRetrieved$]="answerResultRetrieved$"
    [answerResult$]="answerResult$"
    [question$]="question$"
    [loadingMessage$]="loadingMessage$"
    [questionSubmissionError$]="questionSubmissionError$"
    [isExpired$]="isExpired$"
    [expirationDate$]="expirationDate$"
    (questionSet)="setQuestion($event)"
    (answerResultSet)="setAnswerResult($event)"
    (answerSubmitted)="handleSubmit($event)"
    (initiateReattempt)="reattemptGo()"
    [courseDashboardUrl]="courseDashboardUrl"
  ></app-platform-question-display>
</div>
<!--<button (click)="doSomething()">Test</button>-->
