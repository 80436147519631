import { Component, OnInit } from '@angular/core';
import {BehaviorSubject, Observable, of, Subject, zip} from 'rxjs';
import {catchError, concatMap, finalize, map, takeUntil, tap} from 'rxjs/operators';
import {ActivatedRoute, Router} from '@angular/router';
import {QuestionService} from '../../services/questions/question.service';
import {OnBehalfOfService} from '../../services/on-behalf-of.service';
import {ApplicationInsightsLoggingService} from '../../services/application-insights-logging.service';
import {Answer, Question} from '../../models/question';
import {SubmittedAnswerResult} from '../../models/submitted-answer-result';
import {AnsweredQuestion, AnsweredQuestionAnswer, IQuestionAnswer} from '../../models/answered-question';
import * as moment from "moment/moment";
import {AnalyticEvents, QuestionViewedEvent} from "../../shared/AnalyticEvents";
import {QuestionViewSessionManagerService} from "../../services/question-view-session-manager.service";
import {environment} from "../../../environments/environment";

@Component({
  selector: 'app-platform-question-container',
  templateUrl: './platform-question-container.component.html',
  styleUrls: ['./platform-question-container.component.scss']
})
export class PlatformQuestionContainerComponent implements OnInit {
  slug: string;
  question: Question = null;
  questionDisplay: Question = null;
  questionDisplayInSession = false;
  answerResult: SubmittedAnswerResult = null;
  answerResultRetrieved = false;
  error = false;
  attemptNo: number;
  learnerMatch = true;
  activeEmail = null;
  questionRoute = null;
  isDemo = false;
  sendView = false;
  loadingMessage$ = new BehaviorSubject<string>(null);
  question$ = new BehaviorSubject<Question>(null);
  answerResult$ = new BehaviorSubject<SubmittedAnswerResult>(null);
  answerResultRetrieved$ = new BehaviorSubject<boolean>(false);
  questionSubmissionError$ = new BehaviorSubject<boolean>(false);
  isExpired$ = new BehaviorSubject<boolean>(false);
  expirationDate$ = new BehaviorSubject<string>(null);
  quizType: string;
  courseDashboardUrl: string;
  catalogUrl = environment.catalogUrl;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private questionService: QuestionService,
    private questionViewSession: QuestionViewSessionManagerService,
    private oboService: OnBehalfOfService,
    private logger: ApplicationInsightsLoggingService
  ) {}

  ngOnInit() {
    this.loadingMessage$.next('Please wait while we get some basic information about your question');
    const routeParams$ = this.route.params;
    const queryParams$ = this.route.queryParams;
    let queryParams = null;
    const paramsConfigured$ = zip(routeParams$, queryParams$, this.route.url)
      .pipe(
        map(params => {
          this.isDemo = params[2][1].path === 'd';
          queryParams = params[1];
          if (params[0].slug !== undefined) {
            this.slug = params[0].slug;
            this.questionDisplayInSession = sessionStorage.getItem(this.slug) != null;
          }
          if (params[1].attempt !== undefined) {
            this.attemptNo = params[1].attempt;
          }
        })
      );
    paramsConfigured$.pipe(
      map(params => {
        if (this.isDemo) {
          localStorage.setItem('platform-user', 'DEMOUSER');
          return;
        }
        localStorage.setItem('platform-user-slug', this.slug);
        localStorage.setItem('platform-user-redirect', this.router.url);
      }),
      tap(() => {
        if (this.isDemo) {
          return;
        }
        this.sendView = true;
        if (this.oboService.onBehalfOfValue !== localStorage.getItem('platform-user-slug')) {
          this.learnerMatch = false;
          this.activeEmail = queryParams.email;
          this.questionRoute = window.location.href.split('?')[0];
        }
      }),
      concatMap(() => {
        if (this.questionDisplayInSession) {
          return of(JSON.parse(sessionStorage.getItem(this.slug)));
        }
        return this.getQuestionSubscriberHelper(false);
      }),
      tap(questionDisplay =>  {
        this.questionDisplay = questionDisplay;
        if (!this.questionDisplayInSession) {
          sessionStorage.setItem(this.slug, JSON.stringify(this.questionDisplay));
        }
      }),
      concatMap(() => {
        if (this.isDemo) {
          return of([]);
        }
        this.loadingMessage$.next('We\'re loading your question details - you can view the question while you wait');
        return this.questionService
          .getLmsQuestionDetails(this.questionDisplay.questionDetailsUrl)
          .pipe(
            concatMap(data => {
              this.question$.next(data);
              this.courseDashboardUrl = this.catalogUrl + 'dashboard/courses/' + data.remoteCourseId.toString();
              this.quizType = data.platformQuizType;
              this.checkIfExpired(data);
              this.setQuestion(this.question$.value);
              if ((this.attemptNo != null && this.attemptNo > 0)
                || (!data.canAnswer && data.numberAttempts > 0)) {
                if (this.attemptNo == null) {
                  this.attemptNo = data.numberAttempts;
                }
                return this
                  .questionService
                  .getLmsQuestionAnswerResult(this.slug, this.attemptNo);
              } else {
                return of([]);
              }
            }),
            map(data => {
              if (!(data == null || data.length === 0)) {
                this.answerResult = data;
                this.answerResult$.next(data);
              }
            }),
            tap(() => this.setAnswerResult(this.answerResult$.value)),
            tap(() => this.answerResultRetrieved$.next(true)),
          )
      }),
      concatMap(() => {
        if (this.sendView || (localStorage.getItem('_nV') != null && localStorage.getItem('_nV') === '1')) {
          localStorage.removeItem('_nV');
          const logAttemptNo =  this.attemptNo != null ? this.attemptNo : this.question.numberAttempts + 1;
          this.logger.logEvent(
            AnalyticEvents.QuestionViewed,
            new QuestionViewedEvent(this.slug, this.questionDisplay.remoteQuestionId, logAttemptNo)
          );
          return this.questionViewSession.sendView(this.slug);
        }
        return of([]);
      }),
      catchError(err => {
        if (!this.learnerMatch) {
          sessionStorage.clear();
        }
        if (err.status !== 404) {
          this.error = true;
          this.logger.logException(new Error(err.message));
        }
        if (err.status === 404) {
          this.error = true;
        }
        this.loadingMessage$.next(null);
        this.error = true;
        this.logger.logException(new Error(err.message));
        return of();
      }),
      finalize(() => this.loadingMessage$.next(null))
    ).subscribe(() => {
      this.loadingMessage$.next(null);
    });
  }

  setQuestion(event: Question): void {
    this.question = event;
  }

  setAnswerResult(event): void {
    this.answerResultRetrieved = true;
    this.answerResult = event;
  }

  setAnswerResultRetrieved(event: boolean): void {
    this.answerResultRetrieved$.next(event);
  }

  getQuestionSubscriberHelper(withUserDetails: boolean = true) {
    if (this.isDemo) {
      return this.questionService.getLmsDemoQuestion(this.slug);
    }

    if (withUserDetails) {
      return this.questionService.getLmsQuestionDetails(this.questionDisplay.questionDetailsUrl);
    }

    return this.questionService.getLmsQuestionInfo(this.slug);
  }

  handleSubmit(answerSent: IQuestionAnswer): void {
    answerSent.question = this.question$.value;
    const answeredQuestion = new AnsweredQuestion();
    const answer = new AnsweredQuestionAnswer();
    answer.id = this.questionDisplay.remoteQuestionId;
    answer.submitted = answerSent;
    const questionRep = this.question$.value;
    answeredQuestion.attempt = this.isDemo ? this.attemptNo : questionRep.submission.attempt;
    answeredQuestion.submissionId = questionRep.submission.id;
    answeredQuestion.submission = questionRep.submission;
    answeredQuestion.validationToken = questionRep.submission.validationToken;
    answeredQuestion.answer = [answer];
    answeredQuestion.questionType = questionRep.questionType;
    answeredQuestion.platformQuizType = questionRep.platformQuizType;
    const submittedAnswer$ = this.isDemo
      ? this.questionService.submitDemoAnswer(answeredQuestion)
      : this.questionService.submitAnswerBySlug(this.slug, answeredQuestion);
    this.handleSubmittedAnswer(submittedAnswer$);
  }

  handleSubmittedAnswer(submittedAnswer: Observable<any>) {
    this.answerResultRetrieved$.next(false);
    this.question$.next(this.questionDisplay);
    submittedAnswer.pipe(
      map(answerResult => {
        this.answerResult$.next(answerResult);
        this.answerResult = answerResult;
        this.answerResultRetrieved$.next(true);
      }),
      concatMap(() => this.getQuestionSubscriberHelper()),
      tap(question => {
        this.question$.next(question);
      }),
      map(() => {
        this.logger.logEvent(AnalyticEvents.QuestionAnswered, {
          slug: this.slug,
          answer: JSON.stringify(this.answerResult.answer),
          remoteQuestionId: this.questionDisplay.remoteQuestionId.toString(),
          attemptNumber: (this.question.numberAttempts + 1).toString()
        })
      }),
      finalize(() => {})
    ).subscribe(
      () => {},
      err => this.questionSubmissionError$.next(true)
    );
  }

  reattemptGo() {
    if (!this.isDemo) {
      localStorage.setItem('_nV', '1');
    }
    window.location.href = `${window.location.pathname}`;
  }

  checkIfExpired(data: Question): void {
    const expiration = moment.utc(data.expirationDate);
    const now = moment.utc();

    if (Date.parse(expiration.toString()) < Date.parse(now.toString())) {
      this.isExpired$.next(true)
      this.expirationDate$.next(data.expirationDate);
    }
  }

  doSomething(): void {
    this.questionSubmissionError$.next(true);
  }

}
